<template>
  <div class="formInputCountry">
    <select 
      :id="id"
      :name="name"
      v-model="inputValue"
      v-bind:class="{error: !valid && touched && blurred, content: value != ''}"
      v-on:change="touched=true, checkValidation()"
      @blur="blurred = true, checkValidation()" >
        <option value="USA">United States</option>
        <option value="CA">Canada</option>
        <option value="AQ">Antarctica</option>
        <option value="AR">Argentina</option>
        <option value="AT">Austria</option>
        <option value="AU">Australia</option>
        <option value="AW">Aruba</option>
        <option value="BB">Barbados</option>
        <!--<option value="BY">Belarus</option>-->
        <option value="BE">Belgium</option>
        <option value="BM">Bermuda</option>
        <option value="IO">British Indian Ocean Territory</option>
        <option value="BS">Bahamas</option>
        <option value="BZ">Belize</option>
        <option value="KY">Cayman Islands</option>
        <option value="CK">Cook Islands</option>
        <option value="CL">Chile</option>
        <option value="CN">China</option>
        <option value="CO">Colombia</option>
        <option value="CR">Costa Rica</option>
        <option value="HR">Croatia</option>
        <option value="CY">Cyprus</option>
        <option value="CZ">Czech Republic</option>
        <option value="DK">Denmark</option>
        <option value="DO">Dominican Republic</option>
        <option value="SV">El Salvador</option>
        <option value="EC">Ecuador</option>
        <option value="EG">Egypt</option>
        <option value="FI">Finland</option>
        <option value="FJ">Fiji</option>
        <option value="GQ">Equatorial Guinea</option>
        <option value="FK">Falkland Islands (Malvinas)</option>
        <option value="FO">Faroe Islands</option>
        <option value="FR">France</option>
        <option value="FX">France, Metropolitan</option>
        <option value="GF">French Guiana</option>
        <option value="PF">French Polynesia</option>
        <option value="TF">French Southern Territories</option>
        <option value="GB">Great Britain (UK)</option>
        <option value="GD">Grenada</option>
        <option value="DE">Germany</option>
        <option value="GI">Gibraltar</option>
        <option value="GL">Greenland</option>
        <option value="GN">Guinea</option>
        <option value="GR">Greece</option>
        <option value="GS">S. Georgia and S. Sandwich Isls.</option>
        <option value="GT">Guatemala</option>
        <option value="GU">Guam</option>
        <option value="GW">Guinea-Bissau</option>
        <option value="HM">Heard and McDonald Islands</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hungary</option>
        <option value="ID">Indonesia</option>
        <option value="IE">Ireland</option>
        <option value="IL">Israel</option>
        <!--<option value="IN">India</option>-->
        <option value="IS">Iceland</option>
        <option value="IT">Italy</option>
        <option value="JM">Jamaica</option>
        <option value="JP">Japan</option>
        <option value="JO">Jordan</option>
        <option value="KE">Kenya</option>
        <option value="KR">Korea (South)</option>
        <option value="XK">Kosovo</option>
        <option value="KW">Kuwait</option>
        <option value="LB">Lebanon</option>
        <option value="LI">Liechtenstein</option>
        <option value="LU">Luxembourg</option>
        <option value="MG">Madagascar</option>
        <option value="MH">Marshall Islands</option>
        <option value="MQ">Martinique</option>
        <option value="MT">Malta</option>
        <option value="MY">Malaysia</option>
        <option value="MX">Mexico</option>
        <option value="MS">Montserrat</option>
        <option value="MC">Monaco</option>
        <option value="AN">Netherlands Antilles</option>
        <option value="MP">Northern Mariana Islands</option>
        <option value="NL">Netherlands</option>
        <option value="NO">Norway</option>
        <option value="NP">Nepal</option>
        <option value="NZ">New Zealand (Aotearoa)</option>
        <option value="PA">Panama</option>
        <option value="PG">Papua New Guinea</option>
        <option value="PY">Paraguay</option>
        <option value="PE">Peru</option>
        <option value="PH">Philippines</option>
        <option value="PL">Poland</option>
        <option value="PT">Portugal</option>
        <option value="PR">Puerto Rico</option>
        <option value="SM">San Marino</option>
        <option value="SA">Saudi Arabia</option>
        <option value="SG">Singapore</option>
        <option value="SK">Slovakia</option>
        <option value="SI">Slovenia</option>
        <option value="ZA">South Africa</option>
        <option value="ES">Spain</option>
        <option value="LK">Sri Lanka</option>
        <option value="CH">Switzerland</option>
        <option value="SE">Sweden</option>
        <option value="TH">Thailand</option>
        <option value="TW">Taiwan</option>
        <option value="TN">Tunisia</option>
        <!--<option value="UA">Ukraine</option>-->
        <option value="AE">United Arab Emirates</option>
        <option value="UM">US Minor Outlying Islands</option>
        <option value="UY">Uruguay</option>
        <option value="VA">Vatican City State (Holy See)</option>
        <option value="VE">Venezuela</option>
        <option value="VN">Vietnam</option>
        <option value="VG">Virgin Islands (British)</option>
        <option value="VI">Virgin Islands (U.S.)</option>
    </select>
    <label v-if="label" :for="id">{{label}}</label>
  </div><!--END formInputCountry -->
</template>

<script>
export default {
  name: "FormInputCountry",
  data: function() {
    return {
      touched: false,
      blurred: false,
      valid: false
    }
  },
  props: {
    name: { type: String },
    value: { type: String },
    id: { type: String },
    label: { type: String },
    validation: { type: String, default: "none" }
  },
  mounted() {
    if(this.value != null && this.value != ""){
      this.checkValidation();
    }
  },
  computed: {
    inputValue: {
      get () {
        if(this.touched){
          this.checkValidation();
        }
        return this.value;
      },
      set (newValue) {        
        this.$emit('change', newValue);
        this.checkValidation();
      }
    }
  },
  methods: {
    checkValidation() {
      this.touched = true;
      this.blurred = true;
      if(this.value === "") {
        this.valid = false;
        this.$emit('notValid', this.valid, event);
      } else {
        this.valid = true;
        this.$emit('valid', this.valid, event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.formInputCountry {
  position: relative;
  width: 100%;


/* Change autofill color ;) */
:-webkit-autofill,
:-webkit-autofill:hover, 
:-webkit-autofill:focus, 
:-webkit-autofill:active  {
      border: 1px solid rgba(0,0,0,0.3);
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.3) inset;
      transition: background-color 5000s ease-in-out 0s;
}

  select {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;

    optgroup {
      color: #000000;
    }
    optgroup option,
    option {
      color: #000000;
      font-size: 12px;
    }
  }

  .error {
    border: 1px solid red;
  }

  select:focus + label,
  select.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
  }

}
</style>