<template>
  <div v-bind:class="{inline: inline}" class="creditCardNumber">
    
    <input :id="id" type="text" :name="name" v-model="inputValue" v-bind:class="{error: !valid && touched && blurred, content: value != null && value != '', typeV: type=='V', typeM: type=='M', typeA: type==='A' }" v-on:input="touched = true" @blur="blurred = true" />
    <label v-if="label" :for="id">{{label}}</label>
  </div><!--END formInputText -->
</template>

<script>
export default {
  name: "CreditCardNumber",
  data: function() {
    return {
      touched: false,
      blurred: false,
      valid: false,
      type: "I",
      formatted: '',
      block1: '',
      block2: '',
      block3: '',
      block4: ''
    }
  },
  props: {
    name: { type: String },
    id: { type: String },
    label: { type: String },
    value: { type: String },
    inline: {type: Boolean, default: false}
  },
  mounted() {
    if(this.value != null && this.value != ""){
      this.formatInput(this.value);
      this.checkValidation();
    }
  },
  computed: {
    inputValue: {
      get () {
        if(this.touched){
          this.checkValidation();
        }
        return this.value;
      },
      set (newValue) {     
        newValue = this.formatInput(newValue);
        this.$emit('input', newValue);
        this.checkValidation(newValue);
      }
    }
  },
  methods: {
    formatInput(strToFormat) {

      // reset card type
      this.type="I"; //Invalid

      //remove spaces
      let formattedStr=strToFormat.replace(/\s/g, '');

      // Check there are enough digits to return card type
      if(formattedStr.length < 2) {
        return strToFormat;
      }
      // Figure out what type of card 

      // Get the first two numbers 
      var typeCheck = formattedStr.substring(0,2);

      // Check if there are enough numbers entered to determine type 
      if(typeCheck.length >= 2) {
        // Convert to intiger 
        typeCheck=parseInt(typeCheck);

        // Determine card type 
        if(typeCheck >= 40 && typeCheck <=49) {
          this.type = "V";
        } else if ((typeCheck >= 51 && typeCheck <= 55) || (typeCheck >=22 && typeCheck <=27)) {
          this.type = "M";
        } else if (typeCheck === 34 || typeCheck === 37) {
          this.type = "A";
        } else {
          this.type = "I";//Invalid
        }        
      }

      // Format input 
      if(this.type != "I"){

        // All supported card types have a 4 digit first block 
        // Get the first 4 numbers 
        this.block1 = formattedStr.substring(0,4);
        if(formattedStr.length > 4) {
          // Adding space 
          this.block1 = this.block1 + ' ';
        }

        if (this.type === 'V' || this.type === 'M') {
              // for 4X4 cards 
              this.block2 = formattedStr.substring(4, 8);
              if (formattedStr.length > 8) {
                  this.block2=this.block2 + ' ';
              }
              this.block3 = formattedStr.substring(8, 12);
              if (formattedStr.length > 12) {
                  this.block3=this.block3 + ' ';
              }
              this.block4 = formattedStr.substring(12,16);

          } else if (this.type === 'A') {
              // for Amex cards 
              this.block2 =  formattedStr.substring(4, 10);
              if (formattedStr.length > 10) {
                  this.block2=this.block2 + ' ';
              }
              this.block3 =  formattedStr.substring(10, 15);
              this.block4='';
          } 
        
        return this.block1 + this.block2 + this.block3 + this.block4;
      }else{
        return strToFormat;
      }
      

    },
    checkValidation(strToValidate=this.value) {

      this.touched = true;
      this.blurred = true;

      /* Check if ccNumber passes Luhn test */
      if(strToValidate != null && strToValidate.length > 0 && this.type !="I" && this.ccLuhnFormula(strToValidate)) {
        // check for the correct length for Visa/Mastercard
        if(this.type ==='V' || this.type==='M'){
          if(strToValidate.length == 19){ //digits with spaces
            this.valid = true;
          }else{
            this.valid = false;
          }
        }else if(this.type ==='A'){
          if(strToValidate.length == 17){ //digits with spaces
            this.valid = true;
          }else{
            this.valid = false;
          }  
        }
        
      }else {
        this.valid = false;
      }
        
      /* Emit if valid or not */
      if(this.valid) {
        this.$emit('valid', this.valid, event);
      }else {
        this.$emit('notValid', this.valid, event);
      }
    },
    ccLuhnFormula(input) {
    /* validates credit card number using the Luhn formula */
      input=input.replace(/\s/g, ''); // remove spaces
      input.replace(/[^0-9]/g, ""); /* remove everything that is not a digit(0-9) */
      var sum = 0;
      var numdigits = input.length;
      var parity = numdigits % 2;
      for(var i=0; i < numdigits; i++) { /* loop through each digit */
        var digit = parseInt(input.charAt(i)); /* convert to digit */
        if(i % 2 == parity) digit *= 2; /* double every second digit, right to left */
        if(digit > 9) digit -= 9; /* Add digits above 9(or subtrack 9) */
        sum += digit; /* Sum all digits */
      }
      return (sum % 10) == 0; /* Valid if it's a multiple of 10 */
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.creditCardNumber {
  position: relative;
  width: 100%;

/* Change autofill color ;) */
:-webkit-autofill,
:-webkit-autofill:hover, 
:-webkit-autofill:focus, 
:-webkit-autofill:active  {
      border: 1px solid rgba(0,0,0,0.3);
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.3) inset;
      transition: background-color 5000s ease-in-out 0s;
}
  input {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;
  }

  input.typeV,
  input.typeM,
  input.typeA{
    background-size: 32px;
    background-repeat:no-repeat;
    background-position:right 8px center;
  }
  input.typeV{
    background-image: url($images-url + "card-logo-visa.svg");
  }
  input.typeM{
    background-image: url($images-url + "card-logo-mastercard.svg");
  }
  input.typeA{
    background-image: url($images-url + "card-logo-amex.svg");
  }

  .error {
    border: 1px solid red;
  }

  &.inline input + label,
  input:focus + label,
  input.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size:12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }

}
</style>