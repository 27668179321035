<template>
  <div v-bind:class="{inline: inline}" class="formInputText">
    
    <input :id="id" type="text" :name="name" v-model="inputValue" v-bind:class="{error: !valid && touched && blurred, content: value != null && value != ''}" v-on:input="checkValidation()" @blur="checkValidation()" />
    <label v-if="label" :for="id">{{label}}</label>
  </div><!--END formInputText -->
</template>

<script>
export default {
  name: "FormInputText",
  data: function() {
    return {
      touched: false,
      blurred: false,
      valid: false
    }
  },
  props: {
    name: { type: String },
    id: { type: String },
    label: { type: String },
    value: { type: String },
    validation: { type: String, default: "none" },
    inline: {type: Boolean, default: false}
  },
  mounted() {
    if(this.value != null && this.value !=""){
      this.checkValidation();
    }
  },
  computed: {
    inputValue: {
      get () {
        if(this.touched){
          this.checkValidation();
        }
        return this.value;
      },
      set (newValue) {        
        this.$emit('input', newValue);
        this.checkValidation();
      }
    }
  },
  methods: {
    checkValidation() {
      this.touched = true;
      this.blurred = true;
      if(this.validation ==="none") {
        this.valid = true;
      } else if(this.validation ==="required") {
        if(this.value != null && this.value.length > 0) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      } else if(this.validation ==="email") {
        var emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(emailRE.test(this.value)) {
          this.valid = true;
        } else {
          this.valid = false;
        }
        
      } else if(this.validation ==="phone") {
        var phoneRE= /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        if(phoneRE.test(this.value)) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      } else if(this.validation ==="zip-usa") { // USA zip code format: NNNNN
        var zipRE= /^\d{5}$|^\d{5}-\d{4}$/;      
        if(zipRE.test(this.value)) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      } else if(this.validation ==="zip-ca") { // Canada zip code format: ANA NAN
        var zipRE= /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;      
        if(zipRE.test(this.value)) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      } else if(this.validation === "ccNumber") {       
        /* Check if ccNumber passes Luhn test */
        if(this.ccLuhnFormula(this.value)) {
          this.valid = true;
        }else {
          this.valid = false;
        }
      } else {
        this.valid = false;
      }

      /* Emit if valid or not */
      if(this.valid) {
        this.$emit('valid', this.valid, event);
      }else {
        this.$emit('notValid', this.valid, event);
      }

    },
    ccLuhnFormula(input) {
    /* validates credit card number using the Luhn formula */
      input.replace(/[^0-9]/g, ""); /* remove everything that is not a digit(0-9) */
      var sum = 0;
      var numdigits = input.length;
      var parity = numdigits % 2;
      for(var i=0; i < numdigits; i++) { /* loop through each digit */
        var digit = parseInt(input.charAt(i)); /* convert to digit */
        if(i % 2 == parity) digit *= 2; /* double every second digit, right to left */
        if(digit > 9) digit -= 9; /* Add digits above 9(or subtrack 9) */
        sum += digit; /* Sum all digits */
      }
      return (sum % 10) == 0; /* Valid if it's a multiple of 10 */
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.formInputText {
  position: relative;
  width: 100%;

  /* Change autofill color ;) */
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
      border: 1px solid rgba(0,0,0,0.3);
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0.3) inset;
      transition: background-color 5000s ease-in-out 0s;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;


  }

  .error {
    border: 1px solid red;
  }

  &.inline input + label,
  input:focus + label,
  input.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size:12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }

}
</style>