<template>
  <div class="checkout contentWrapper">
    <h1>Checkout</h1>

    <!-- Empty Cart -->
    <div
      class="continueShoppingWrapper"
      v-if="currentCart.current_cart.total_item_count === 0 && !orderSent"
    >
      <h2>Your cart is empty.</h2>
      <router-link class="btn" to="/">Continue Shopping</router-link>
    </div>

    <!-- Processing Order -->
    <div
      v-if="orderSent && !checkoutSummaryData"
      class="orderProcessingWrapper"
    >
      <img
        class="loadingSpinner"
        src="@/assets/images/spinner.svg"
        alt="Loading Spinner"
      />
      <p>Processing your order</p>
    </div>
    <!--END orderProcessingWrapper-->

    <!-- Order Summary -->
    <div v-if="orderSent && checkoutSummaryData" class="orderSummaryWrapper">
      <div v-if="checkoutSummaryData.order_placed">
        <h2>Thank You</h2>
        Your order confirmation is being sent to:<br />
        <b>{{ checkoutSummaryData.email }}</b
        ><br /><br />
        Your order number is:<br />
        <b>{{ checkoutSummaryData.order_id }}</b>
      </div>
      <div v-else>
        <h2>Order Failed</h2>   
        <div v-if="checkoutSummaryData.status_message">
          <div v-if="checkoutSummaryData.status_message =='credit failed'">
            Payment Failed. Please change your payment method or try again later.
          </div>
          <div v-else>
            Status: {{checkoutSummaryData.status_message}}
          </div>
        </div>
        <div v-if="checkoutSummaryData.errorResponse">
          <div class="failed-order-summary">
            Please refresh the page to try again or <router-link to="/help">contact us</router-link><br> and include the information below. <br>
          </div>
        
          <div class="failed-order-details">
            <div>
              Email:{{currentCheckoutData.shipping.contact.email}}<br>
              Phone:{{currentCheckoutData.shipping.contact.phone}}<br>
              Name:{{currentCheckoutData.shipping.contact.first_name}} {{currentCheckoutData.shipping.contact.last_name}}<br>
              Shipping<br>
              Address1:{{currentCheckoutData.shipping.address.address_1}}<br>
              Address2:{{currentCheckoutData.shipping.address.address_2}}<br>
              City:{{currentCheckoutData.shipping.address.city}}<br>
              State/Prov:{{currentCheckoutData.shipping.address.state_prov}}<br>
              Zip:{{currentCheckoutData.shipping.address.zip_code}}<br>
              Country:{{currentCheckoutData.shipping.address.country}}<br>
              Billing<br>
              Address1:{{currentCheckoutData.billing.address.address_1}}<br>
              Address2:{{currentCheckoutData.billing.address.address_2}}<br>
              City:{{currentCheckoutData.billing.address.city}}<br>
              State/Prov:{{currentCheckoutData.billing.address.state_prov}}<br>
              Zip:{{currentCheckoutData.billing.address.zip_code}}<br>
              Country:{{currentCheckoutData.billing.address.country}}<br>
            </div>
            <div>
              Error:{{checkoutSummaryData.errorResponse.code}}<br> {{checkoutSummaryData.errorResponse.message}}<br>
              Cart:{{currentCheckoutData.cart_id}}<br>
              Id:{{currentCheckoutData.order_id}}<br>
              Shipping:{{currentCheckoutData.shipping.selected_rate.description}} - ${{currentCheckoutData.shipping.selected_rate.price}}<br>
              Payment:{{currentCheckoutData.billing.credit_card.type}}<br>
              Coupon:{{currentCart.current_cart.coupon_discount}}<br>
              Items:<br>
              <div v-for="item in currentCart.current_cart.items" :key="item.id">
                {{item.quantity}} - {{item.name}} - {{item.unit_price}}<br>
              </div>
            </div>
          </div>
        </div>
        
        <!--
        <div v-if="checkoutSummaryData.errorResponse.stack">Error Stack: {{checkoutSummaryData.errorResponse.stack}}</div>
        

        <div class="failed-order-summary">
          Please refresh the page to try again or <router-link to="/help">contact us</router-link><br> and include the information below. <br>
        </div>
        -->


      </div>
    </div>
    <!--END orderSentWrapper-->

    <div>
      <div
        v-if="currentCart.current_cart.total_item_count > 0 && !orderSent"
        class="colsWrapper"
      >
        <div class="col">
          <fieldset>
            <legend>Contact Info</legend>
            <form-input-text
              label="E-mail Address"
              ref="shippingEmail"
              id="shippingEmail"
              tabIndex="2"
              name="shipping.contact.email"
              :value="currentCheckoutData.shipping.contact.email"
              v-on:input="shippingBillingChange"
              v-on:valid="validate('emailValid', true)"
              v-on:notValid="validate('emailValid', false)"
              validation="email"
            >
            </form-input-text>
            <form-input-text
              label="Phone Number"
              ref="shippingPhone"
              id="shippingPhone"
              tabIndex="3"
              name="shipping.contact.phone"
              :value="currentCheckoutData.shipping.contact.phone"
              v-on:input="shippingBillingChange"
              v-on:valid="validate('phoneValid', true)"
              v-on:notValid="validate('phoneValid', false)"
              validation="phone"
            >
            </form-input-text>
          </fieldset>
          <fieldset>
            <legend>P.O. Number</legend>
            <form-input-text
              label="Purchase Order Number (Optional)"
              id="poNumber"
              tabIndex="5"
              name="po_number"
              :value="currentCheckoutData.po_number"
              v-on:input="checkoutChange"
            >
            </form-input-text>
          </fieldset>
        </div>
        <!--END col-->
        <div class="col">
          <div class="checkoutDetails">
            <h1>1 Year Limited Warranty</h1>
            <p>
              NaturalPoint backs up all of its products with a 1 year limited <router-link to="/warranty"
                >warranty</router-link>. If you need to return a product to us, please review our <router-link to="/warranty"
                >return policy</router-link> to verify that you are eligible.
            </p>
            <h1>We Ship Worldwide</h1>
            <p>
              If your country is not listed in the form, visit our
              <router-link to="/dealers">resellers page</router-link> for
              dealers in your area or
              <a
                href="mailto:sales@naturalpoint.com?subject=International Order"
                >contact</a
              >
              our sales team.
            </p>
          </div>
          <!--END checkoutDetails-->
        </div>
        <!--END col-->
      </div>
      <!--END colsWrapper-->
      <div
        v-if="currentCart.current_cart.total_item_count > 0 && !orderSent"
        class="colsWrapper"
      >
        <div class="col">
          <fieldset>
            <legend>
              Ship To
              <div class="legendDetails">No PO Boxes</div>
            </legend>
            <div class="twoCol">
              <form-input-text
                label="First Name"
                ref="firstName"
                id="firstName"
                tabIndex="0"
                name="shipping.contact.first_name"
                :value="currentCheckoutData.shipping.contact.first_name"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('firstNameValid', true)"
                v-on:notValid="validate('firstNameValid', false)"
                validation="required"
              >
              </form-input-text>
              <form-input-text
                label="Last Name"
                ref="lastName"
                id="lastName"
                tabIndex="1"
                name="shipping.contact.last_name"
                :value="currentCheckoutData.shipping.contact.last_name"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('lastNameValid', true)"
                v-on:notValid="validate('lastNameValid', false)"
                validation="required"
              >
              </form-input-text>
            </div>
            <!--END twoCol-->
            <form-input-text
              label="Organization (Optional)"
              id="shippingOrganization"
              tabIndex="4"
              name="shipping.contact.organization"
              :value="currentCheckoutData.shipping.contact.organization"
              v-on:input="shippingBillingChange"
            >
            </form-input-text>
            <form-input-text
              label="Street Address"
              ref="shippingAddress1"
              id="shippingAddress1"
              tabIndex="6"
              name="shipping.address.address_1"
              :value="currentCheckoutData.shipping.address.address_1"
              v-on:input="shippingBillingChange"
              v-on:valid="validate('street1Valid', true)"
              v-on:notValid="validate('street1Valid', false)"
              validation="required"
            >
            </form-input-text>
            <form-input-text
              label="Apt, Suite, Building (Optional)"
              id="shippingAddress2"
              tabIndex="7"
              name="shipping.address.address_2"
              :value="currentCheckoutData.shipping.address.address_2"
              v-on:input="shippingBillingChange"
            >
            </form-input-text>
            <div class="threeCol">
              <form-input-text
                label="City"
                ref="shippingCity"
                id="shippingCity"
                tabIndex="8"
                name="shipping.address.city"
                :value="currentCheckoutData.shipping.address.city"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('cityValid', true)"
                v-on:notValid="validate('cityValid', false)"
                validation="required"
              >
              </form-input-text>
              <form-input-state
                label="State/Prov"
                ref="shippingState"
                id="shippingState"
                tabIndex="9"
                name="shipping.address.state_prov"
                :value="currentCheckoutData.shipping.address.state_prov"
                v-on:change="shippingBillingChange"
                v-on:valid="validate('stateValid', true)"
                v-on:notValid="validate('stateValid', false)"
                validation="required"
              >
              </form-input-state>
              <form-input-text
                label="Zip"
                ref="shippingZip"
                id="shippingZip"
                tabIndex="10"
                name="shipping.address.zip_code"
                :value="currentCheckoutData.shipping.address.zip_code"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('zipValid', true)"
                v-on:notValid="validate('zipValid', false)"
                :validation=shippingZipValidationType
              >
              </form-input-text>
            </div>
            <!--END threeCol-->
            <div class="countryInput">
              <form-input-country
                label="Country"
                ref="country"
                id="country"
                tabIndex="11"
                name="shipping.address.country"
                :value="currentCheckoutData.shipping.address.country"
                v-on:change="shippingBillingChange"
                v-on:valid="validate('countryValid', true)"
                v-on:notValid="validate('countryValid', false)"
              >
              </form-input-country>
              <p class="inputHint">
                If your country is not listed, please <a href="mailto:sales@naturalpoint.com?subject=International Order">contact</a> the sales
                team to place your order.
              </p>
            </div>
            <!--END countryInput-->
            <form-input-text
              v-if="
                currentCheckoutData.shipping.address.state_prov ===
                  'International' ||
                currentCheckoutData.shipping.address.country === 'CA'
              "
              label="VAT/Tax ID (Optional)"
              ref="vat"
              id="vat"
              tabIndex="11"
              name="shipping.shipping_tax_id_number"
              :value="currentCheckoutData.shipping.shipping_tax_id_number"
              v-on:input="checkoutChange"
            >
            </form-input-text>
          </fieldset>
        </div>
        <!--END col-->
        <div
          v-if="currentCart.current_cart.total_item_count > 0 && !orderSent"
          class="col"
        >
          <fieldset>
            <legend>
              Billing
              <div class="legendDetails">
                <div class="customCheckContainer">
                  <input
                    type="checkbox"
                    v-model="billingSame"
                    v-on:change="syncBillingChange"
                  />
                  <div class="customCheck"></div>
                </div>
                Same as shipping
              </div>
              <!--END legendDetails-->
            </legend>
            <div class="twoCol">
              <form-input-text
                label="First Name"
                ref="billingFirstName"
                id="billingFirstName"
                tabIndex="0"
                name="billing.contact.first_name"
                :value="currentCheckoutData.billing.contact.first_name"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('billingFirstNameValid', true)"
                v-on:notValid="validate('billingFirstNameValid', false)"
                validation="required"
              >
              </form-input-text>
              <form-input-text
                label="Last Name"
                ref="billingLastName"
                id="billingLastName"
                tabIndex="1"
                name="billing.contact.last_name"
                :value="currentCheckoutData.billing.contact.last_name"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('billingLastNameValid', true)"
                v-on:notValid="validate('billingLastNameValid', false)"
                validation="required"
              >
              </form-input-text>
            </div>
            <!--END twoCol-->
            <form-input-text
              label="Organization (Optional)"
              ref="billingOrganization"
              id="billingOrganization"
              tabIndex="4"
              name="billing.contact.organization"
              :value="currentCheckoutData.billing.contact.organization"
              v-on:input="shippingBillingChange"
            >
            </form-input-text>
            <form-input-text
              label="Street Address"
              ref="billingAddress1"
              id="billingAddress1"
              tabIndex="12"
              name="billing.address.address_1"
              :value="currentCheckoutData.billing.address.address_1"
              v-on:input="shippingBillingChange"
              v-on:valid="validate('billingStreet1Valid', true)"
              v-on:notValid="validate('billingStreet1Valid', false)"
              validation="required"
            >
            </form-input-text>
            <form-input-text
              label="Apt, Suite, Building (Optional)"
              ref="billingAddress2"
              id="billingAddress2"
              tabIndex="13"
              name="billing.address.address_2"
              :value="currentCheckoutData.billing.address.address_2"
              v-on:input="shippingBillingChange"
            >
            </form-input-text>
            <div class="threeCol">
              <form-input-text
                label="City"
                ref="billingCity"
                id="billingCity"
                tabIndex="14"
                name="billing.address.city"
                :value="currentCheckoutData.billing.address.city"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('billingCityValid', true)"
                v-on:notValid="validate('billingCityValid', false)"
                validation="required"
              >
              </form-input-text>
              <form-input-state
                label="State/Prov"
                ref="billingState"
                id="billingState"
                tabIndex="15"
                name="billing.address.state_prov"
                :value="currentCheckoutData.billing.address.state_prov"
                v-on:change="shippingBillingChange"
                v-on:valid="validate('billingStateValid', true)"
                v-on:notValid="validate('billingStateValid', false)"
                validation="required"
              >
              </form-input-state>
              <form-input-text
                label="Zip"
                ref="billingZip"
                id="billingZip"
                tabIndex="16"
                name="billing.address.zip_code"
                :value="currentCheckoutData.billing.address.zip_code"
                v-on:input="shippingBillingChange"
                v-on:valid="validate('billingZipValid', true)"
                v-on:notValid="validate('billingZipValid', false)"
                :validation=billingZipValidationType
              >
              </form-input-text>
            </div>
            <!--END threeCols-->
            <div class="countryInput">
              <form-input-country
                label="Country"
                ref="billingCountry"
                id="billingCountry"
                tabIndex="11"
                name="billing.address.country"
                :value="currentCheckoutData.billing.address.country"
                v-on:change="shippingBillingChange"
                v-on:valid="validate('billingCountryValid', true)"
                v-on:notValid="validate('billingCountryValid', false)"
              >
              </form-input-country>
              <p class="inputHint">
                If your country is not listed, please <a href="mailto:sales@naturalpoint.com?subject=International Order">contact</a> the sales team to place your order.
              </p>
            </div>
            <!--END countryInput-->
          </fieldset>
        </div>
        <!--END col-->
      </div>
      <!--END colsWrapper-->
      <div
        v-if="currentCart.current_cart.total_item_count > 0 && !orderSent"
        class="colsWrapper"
      >
        <div class="col">
          <fieldset>
            <legend>Shipping Method</legend>
            <div id="shippingMethodMessage" v-if="!shippingMethodValid && !loadingShippingRates">
              To Be Determined
              <p class="details" v-bind:class="{ errorText: shippingAddressChecked }">Please enter a valid shipping address.</p>
            </div>
            <div v-if="loadingShippingRates">
              <img
                class="loadingSpinner"
                src="@/assets/images/spinner.svg"
                alt="Loading Spinner"
              />
              Shipping rates loading...
            </div>
            <div
              v-if="
                shippingValid &&
                shippingMethodValid &&
                !loadingShippingRates &&
                shippingRatesData
              "
              class="shippingMethod"
            >
              <select
                class="content"
                ref="checkoutShipping"
                v-model="checkoutShipping"
              >
                <option
                  v-for="rate in shippingRatesData.shipping_rates.rates"
                  :value="rate.vendor_code"
                  v-bind:key="rate.vendor_code"
                >
                  {{ rate.description }} - ${{ currencyFormat(rate.price) }}
                </option>
              </select>
              <label for="shippingMethod">Shipping Method</label>
            </div>
            <!--END shippingMethod-->

            <div id="poBoxMessage" class="poBoxMessage" v-if="poBoxDetected"><p class="details">We do not ship to PO Boxes.</p></div><!--END poBoxMessage-->
            <div id="internationalNotice" class="internationalCheck" v-if="currentCheckoutData.billing.address.state_prov ==='International' || currentCheckoutData.billing.address.country ==='CA'">
              <p class="details">
                <b>This shipping amount does not include any tariff or other import fee.</b> If imposed, the delivery agent will be required to collect these fees at the time of delivery. On average, these fees can be as high as 30% of the item’s value; in some regions, they are up to 100% of the item’s value. Please contact your local customs agent if you have any questions, or check for a <router-link to="/dealers">dealer/distributor</router-link> in your region.
              </p>
              <div id="internationalAcknowledge" ref="internationalCheckWrapper" class="legendDetails">
                <div class="customCheckContainer">
                  <input type="checkbox" v-model="internationalCheck" />
                  <div class="customCheck"></div>
                </div>
                I understand that I will be responsible to pay all of my region's taxes or fees.
              </div><!--END legendDetails-->
            </div><!--END internationalCheck-->
        </fieldset>
      </div><!--END col-->
      
      <div v-if="(currentCart.current_cart.total_item_count > 0) && !orderSent" class="col">
        <fieldset>
          <legend>Payment Method</legend>
              <credit-card-number 
                label="Card Number (Visa, Mastercard or AmEx)"
                ref="billingCCNumber"
                id="billingCCNumber"
                tabIndex="17" 
                name="billing.credit_card.number" 
                :value="currentCheckoutData.billing.credit_card.number" 
                v-on:input="checkoutChange" 
                v-on:valid="validate('cardNumberValid',true)" 
                v-on:notValid="validate('cardNumberValid',false)">
              </credit-card-number>
              <div class="threeCol">
                <credit-card-month 
                  label="Exp Month" 
                  ref="billingCCMonth"
                  id="billingCCMonth"
                  tabIndex="18"
                  name="billing.credit_card.expiration_month" 
                  :value="currentCheckoutData.billing.credit_card.expiration_month" 
                  v-on:change="checkoutChange"
                  v-on:valid="validate('cardMonthValid',true)" 
                  v-on:notValid="validate('cardMonthValid',false)" 
                  validation="required">
                </credit-card-month>
                <credit-card-year 
                  label="Exp Year" 
                  ref="billingCCYear"
                  id="billingCCYear"
                  tabIndex="19"
                  name="billing.credit_card.expiration_year" 
                  :value="currentCheckoutData.billing.credit_card.expiration_year" 
                  v-on:change="checkoutChange" 
                  v-on:valid="validate('cardYearValid',true)" 
                  v-on:notValid="validate('cardYearValid',false)"
                  validation="required">
                </credit-card-year>
                <form-input-text 
                  label="CCV" 
                  ref="billingCCCCV"
                  id="billingCCCCV"
                  tabIndex="20"
                  name="billing.credit_card.ccv" 
                  :value="currentCheckoutData.billing.credit_card.ccv" 
                  v-on:input="checkoutChange" 
                  v-on:valid="validate('cardCcvValid',true)" 
                  v-on:notValid="validate('cardCcvValid',false)"
                  validation="required">
                </form-input-text>
              </div><!--END threeCol-->
        </fieldset>
      </div><!--END col-->
      </div><!--END colsWrapper-->

      <div v-if="(currentCart.current_cart.total_item_count > 0) && !orderSent" class="shippingNotice colsWrapper">
          <fieldset>
            <legend>Shipping Notice</legend>
            <p>NaturalPoint is currently shipping in 3-7 business days.</p>
            <p>You will receive a tracking number in a separate email when your order ships.</p>
            <p>Choosing a faster shipping method will not expedite order fulfillment and will only take effect once your order has shipped. Scheduled delivery for regular business days only (Monday thru Friday).</p>
              <div ref="shippingNoticeWrapper" class="legendDetails">
                <div class="customCheckContainer">
                  <input type="checkbox" v-model="shippingNotice" />
                  <div class="customCheck"></div>
                </div>
                Check to acknowledge shipping policy.
              </div><!--END legendDetails-->
          </fieldset>
      </div><!--END colsWrapper-->



      <div class="receipt" v-if="(currentCart.current_cart.total_item_count > 0) && !orderSent">
        <h2>Items</h2>

        <cart-item
          :itemData="item"
          v-for="item in currentCart.current_cart.items"
          :key="item.id"
        ></cart-item>
        <div class="priceRows">
          <div class="subtotalRow">
            <div class="spacer"></div>
            <div class="rowLabel">Subtotal:</div>
            <div class="rowPrice">
              ${{ currencyFormat(totalsData.cart_total) }}
            </div>
          </div>
          <!--END subtotalRow-->

          <div
            class="discountRow"
            v-if="currentCart.current_cart.coupon_discount != 0"
          >
            <div class="spacer"></div>
            <div class="rowLabel">Discount:</div>
            <div class="rowPrice">
              -${{ currencyFormat(currentCart.current_cart.coupon_discount) }}
            </div>
          </div>
          <!--END subtotalRow-->

          <div class="taxesRow" v-if="totalsData.tax != 0">
            <div class="spacer"></div>
            <div class="rowLabel">Taxes:</div>
            <div class="rowPrice">${{ currencyFormat(totalsData.tax) }}</div>
          </div>
          <!--END taxesRow-->

          <div class="shippingRow">
            <div class="spacer"></div>
            <div class="rowLabel">Shipping:</div>
            <div v-if="totalsData.shipping != 0" class="rowPrice">
              ${{ currencyFormat(totalsData.shipping) }}
            </div>
            <div v-if="totalsData.shipping === 0" class="rowPrice">
              --------
            </div>
          </div>
          <!--END shippingRow-->

          <div class="totalRow">
            <div class="rowLabel">Total:</div>
            <div v-if="totalsData.total != 0" class="rowPrice">
              ${{ currencyFormat(totalsData.total) }}
            </div>
            <div v-if="totalsData.total === 0" class="rowPrice">--------</div>
          </div>
          <!--END totalRow-->

          <div class="spacer couponRow">
            <div class="couponInput">
              <form-input-text
                v-if="currentCart.current_cart.total_item_count > 0 && !orderSent"
                label="Coupon Code"
                ref="couponCode"
                id="couponCode"
                tabIndex="21"
                name="coupon"
                :inline="true"
                :value="currentCart.current_cart.coupon_hash"
                v-on:input="updateCouponHash"
                v-on:valid="validate('couponValid', true)"
                v-on:notValid="validate('couponValid', false)"
                validation="required"
              >
              </form-input-text>
            </div><!--END couponInput-->
            <div id="couponMessage" v-if="couponMessage">{{ couponMessage }}</div>
          </div>
        </div>
        <!--END priceRows-->
      </div>
      <!--END receipt-->
      <a
        v-if="currentCart.current_cart.total_item_count > 0 && !orderSent"
        class="btn orderBtn"
        @click.prevent="placeOrder()"
        >Place Order</a
      >
    </div>
    <!--END displayForm-->
  </div>
  <!--END contentWrapper-->
</template>

<script>
import { mapState } from "vuex";
import {
  SET_CHECKOUT_FIELD,
  SET_CHECKOUT_SUMMARY,
  COUPON_HASH,
} from "../store/mutation-types";
import CartItem from "@/components/CartItem";
import FormInputText from "@/components/Inputs/FormInputText";
import FormInputState from "@/components/Inputs/FormInputState";
import FormInputCountry from "@/components/Inputs/FormInputCountry";
import CreditCardNumber from "@/components/Inputs/CreditCardNumber";
import CreditCardMonth from "@/components/Inputs/CreditCardMonth";
import CreditCardYear from "@/components/Inputs/CreditCardYear";
export default {
  name: "checkout",
  data: function () {
    return {
      orderSent: false,
      orderResponse: false,
      billingSame: true,
      formValid: false,
      contactValid: false,
      shippingValid: false,
      billingValid: false,
      shippingMethodValid: false,
      poBoxDetected: false, //if there is a "PO Box" string in the address field
      paymentValid: false,
      shippingNotice: false, //the user has acknowledged the shipping notice
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      phoneValid: false,
      organizationValid: true,
      poNumberValid: true,
      street1Valid: false,
      street2Valid: true,
      cityValid: false,
      stateValid: false,
      zipValid: false,
      countryValid: false,
      loadingShippingRates: false,
      shippingRatesDisplayed: false,
      shippingAddressChecked: false, // did the shipping api check this address yet
      shippingZipValidationType: "zip-usa",
      billingZipValidationType: "zip-usa",
      billingFirstNameValid: false,
      billingLastNameValid: false,
      billingStreet1Valid: false,
      billingStreet2Valid: true,
      billingCityValid: false,
      billingStateValid: false,
      billingZipValid: false,
      billingCountryValid: false,
      internationalCheck: false,
      cardNumberValid: false,
      cardMonthValid: false,
      cardYearValid: false,
      cardCcvValid: false,
      couponValid: true,
      couponResponse: false,
    };
  },
  created() {
    /* Gets the subtotal when the checkout is loaded */
    this.$store.dispatch("calcTotals");

    //this.validateAllInputs();
  },
  computed: {
    ...mapState({
      currentCart: (state) => state.cart,
      currentCheckoutData: (state) => state.checkout.checkoutData,
      shippingRatesData: (state) => state.checkout.destinationDetails,
      totalsData: (state) => state.checkout.totals,
      checkoutSummaryData: (state) => state.checkout.checkoutSummary,
      couponIsInvalid: function() {
        return this.couponResponse && this.currentCart.current_cart.coupon_discount ==0;
      },
      couponMessage: function() {
        if(this.couponIsInvalid){
          return "The coupon you entered is invalid."
        }else if(this.couponResponse && this.couponValid){
          return "Coupon applied."
        }else{
          return null;
        }
      }
    }),
    checkoutShipping: {
      get() {
        if (this.currentCheckoutData) {
          return this.currentCheckoutData.shipping.selected_rate.vendor_code;
        }
        return null;
      },
      set(newValue) {

        if(this.shippingRatesDisplayed){
          var shippingObj = null;

          //loop through the shipping rates
          for (var i = 0; i < this.shippingRatesData.shipping_rates.rates.length; i++) {

            //find the correct shipping rate
            if (this.shippingRatesData.shipping_rates.rates[i].vendor_code ===newValue) {
              shippingObj = this.shippingRatesData.shipping_rates.rates[i];
            }
          }

          //create a formData object and dispatch to store
          var formData = {};
          formData.field = "shipping.selected_rate";
          formData.value = shippingObj;
          this.$store.commit(SET_CHECKOUT_FIELD, formData);
          this.$store.dispatch("calcTotals");
        }
      }
    },
  },
  components: {
    CartItem,
    FormInputText,
    FormInputState,
    FormInputCountry,
    CreditCardNumber,
    CreditCardMonth,
    CreditCardYear,
  },
  methods: {
    setValid(value) {
      //console.log('setValid: '+value);
    },
    currencyFormat(num) {
      if (!num) {
        num = 0;
      }
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getShippingEstimate() {
      this.$store.dispatch("getDestinationDetails").then((response) => {

        //track that the address has been checked by the shipping API
        this.shippingAddressChecked = true;

        //no longer waiting for shipping rates
        this.loadingShippingRates = false;

        //check if any results are returned
        if(this.shippingRatesData.shipping_rates.rates.length > 0){

          // Stop the progress spinner
           this.shippingRatesDisplayed = true;

          /* Set the shipping rate to be the last option */
          var lastOption = this.shippingRatesData.shipping_rates.rates.length - 1;
          this.checkoutShipping = this.shippingRatesData.shipping_rates.rates[lastOption].vendor_code;
          
          //update the state of the checkout
          this.shippingMethodValid = true;
        
        }
      });
    },

    // keeps billing and shipping in sync
    shippingBillingChange: function (newValue) {

      /* Updates shipping country based on state_prov */
      if (event.target.name === "shipping.address.state_prov") {
        this.updateCountryFromStateProv("shipping", newValue);
      }
      /* Updates shipping state_prov based on country */
      if (event.target.name === "shipping.address.country") {
        this.updateStateProvFromCountry("shipping", newValue);
      }


      /* Updates billing country based on state_prov */
      if (event.target.name === "billing.address.state_prov") {
        this.updateCountryFromStateProv("billing", newValue);
      }

      /* Updates billing state_prov base on country */
      if (event.target.name === "billing.address.country") {
        this.updateStateProvFromCountry("billing", newValue);
      }

      //checks if the string "PO Box" is present in the shipping address field
      if(event.target.name ==="shipping.address.address_1"){
        if(newValue.toLowerCase().indexOf('po box') !== -1 || newValue.toLowerCase().indexOf('p.o. box') !== -1){
          this.poBoxDetected=true;
        }else{
          this.poBoxDetected=false;
        }
      }

      /* Sync shipping and billing */
      if (this.billingSame) {
        var shippingFormData = {};
        var billingFormData = {};
        if (
          event.target.name === "shipping.contact.first_name" ||
          event.target.name === "billing.contact.first_name"
        ) {
          shippingFormData.field = "shipping.contact.first_name";
          billingFormData.field = "billing.contact.first_name";
        } else if (
          event.target.name === "shipping.contact.last_name" ||
          event.target.name === "billing.contact.last_name"
        ) {
          shippingFormData.field = "shipping.contact.last_name";
          billingFormData.field = "billing.contact.last_name";
        } else if (
          event.target.name === "shipping.contact.email" ||
          event.target.name === "billing.contact.email"
        ) {
          shippingFormData.field = "shipping.contact.email";
          billingFormData.field = "billing.contact.email";
        } else if (
          event.target.name === "shipping.contact.phone" ||
          event.target.name === "billing.contact.phone"
        ) {
          shippingFormData.field = "shipping.contact.phone";
          billingFormData.field = "billing.contact.phone";
        } else if (
          event.target.name === "shipping.contact.organization" ||
          event.target.name === "billing.contact.organization"
        ) {
          shippingFormData.field = "shipping.contact.organization";
          billingFormData.field = "billing.contact.organization";
        } else if (
          event.target.name === "shipping.address.address_1" ||
          event.target.name === "billing.address.address_1"
        ) {
          shippingFormData.field = "shipping.address.address_1";
          billingFormData.field = "billing.address.address_1";
        } else if (
          event.target.name === "shipping.address.address_2" ||
          event.target.name === "billing.address.address_2"
        ) {
          shippingFormData.field = "shipping.address.address_2";
          billingFormData.field = "billing.address.address_2";
        } else if (
          event.target.name === "shipping.address.city" ||
          event.target.name === "billing.address.city"
        ) {
          shippingFormData.field = "shipping.address.city";
          billingFormData.field = "billing.address.city";
        } else if (
          event.target.name === "shipping.address.state_prov" ||
          event.target.name === "billing.address.state_prov"
        ) {
          shippingFormData.field = "shipping.address.state_prov";
          billingFormData.field = "billing.address.state_prov";
          this.updateCountryFromStateProv("shipping", newValue);
          this.updateCountryFromStateProv("billing", newValue);
        } else if (
          event.target.name === "shipping.address.zip_code" ||
          event.target.name === "billing.address.zip_code"
        ) {
          shippingFormData.field = "shipping.address.zip_code";
          billingFormData.field = "billing.address.zip_code";
        } else if (
          event.target.name === "shipping.address.country" ||
          event.target.name === "billing.address.country"
        ) {
          shippingFormData.field = "shipping.address.country";
          billingFormData.field = "billing.address.country";
          this.updateStateProvFromCountry("shipping", newValue);
          this.updateStateProvFromCountry("billing", newValue);
        }

        
        shippingFormData.value = newValue;
        billingFormData.value = newValue;
        this.$store.commit(SET_CHECKOUT_FIELD, shippingFormData);
        this.$store.commit(SET_CHECKOUT_FIELD, billingFormData);
      } else {

        var formData = {};
        formData.field = event.target.name;
        formData.value = newValue;
        this.$store.commit(SET_CHECKOUT_FIELD, formData);
      }

        //updates shipping zip code requirements based on shipping country
        if(this.currentCheckoutData.shipping.address.country === 'USA' || this.currentCheckoutData.billing.address.country === 'USA'){
          this.billingZipValidationType = 'zip-usa';
          this.shippingZipValidationType = 'zip-usa';
          
        }else if(this.currentCheckoutData.shipping.address.country === 'CA' || this.currentCheckoutData.billing.address.country === 'CA'){
            this.billingZipValidationType = 'zip-ca';
            this.shippingZipValidationType = 'zip-ca';
        }else{
          this.billingZipValidationType = 'required';
          this.shippingZipValidationType = 'required';
        }

      /* Invalidate shipping method if shipping is changed */
      if (this.shippingMethodValid) {
        this.shippingMethodValid = false;
      }

      //track the address has been changed and not checked again
      this.shippingAddressChecked = false;

      //invalidate shipping options if address has changed
      this.checkoutShipping = ""
      this.shippingRatesDisplayed = false;
    },
    updateStateProvFromCountry: function (addressType, newValue) {
      var stateProvFormData = {};
      if (addressType === "shipping") {
        stateProvFormData.field = "shipping.address.state_prov";
      } else if (addressType === "billing") {
        stateProvFormData.field = "billing.address.state_prov";
      } else {
        return;
      }

      if (newValue != "USA" && newValue != "CA") {
        stateProvFormData.value = "International";
        this.$store.commit(SET_CHECKOUT_FIELD, stateProvFormData);
      } 
      
      
    },
    updateCountryFromStateProv: function (addressType, newValue) {

      /* Updates the country based on the state_prov field */
      var countryFormData = {};
      if (addressType === "shipping") {
        countryFormData.field = "shipping.address.country";
      } else if (addressType === "billing") {
        countryFormData.field = "billing.address.country";
      } else {
        return;
      }
      if (newValue === "International") {
        //first value in country select
        countryFormData.value = "USA";
      } else if (
        //check for Canadian providences
        newValue === "AB" ||
        newValue === "BC" ||
        newValue === "MB" ||
        newValue === "NB" ||
        newValue === "NL" ||
        newValue === "NS" ||
        newValue === "NU" ||
        newValue === "NT" ||
        newValue === "ON" ||
        newValue === "QC" ||
        newValue === "PE" ||
        newValue === "SK" ||
        newValue === "YT"
      ) {
        //set as Canada
        countryFormData.value = "CA";
      } else {
        //first value in country select
        countryFormData.value = "USA";
      }
      this.$store.commit(SET_CHECKOUT_FIELD, countryFormData);


      if (this.$refs.country) {
        this.$refs.country.checkValidation();
      }
      if (this.$refs.billingCountry) {
        this.$refs.billingCountry.checkValidation();
      }
    },
    checkoutChange: function (newValue) {
      var formData = {};
      formData.field = event.target.name;
      formData.value = newValue;
      this.$store.commit(SET_CHECKOUT_FIELD, formData);
    },
    updateCouponHash: function(newValue) {
      this.$store.commit(COUPON_HASH, newValue);

      if(newValue.length == 20){
        this.applyCoupon();
      }
    },

    //toggles between billing and shipping being in sync and not
    syncBillingChange: function (newValue) {
      if (this.billingSame) {
        // Copy values from shipping 
        var sData = this.currentCheckoutData.shipping;
        
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.first_name",
          value: sData.contact.first_name,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.last_name",
          value: sData.contact.last_name,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.email",
          value: sData.contact.email,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.phone",
          value: sData.contact.phone,
        });
        
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.organization",
          value: sData.contact.organization,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.address_1",
          value: sData.address.address_1,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.address_2",
          value: sData.address.address_2,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.city",
          value: sData.address.city,
        });
        
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.state_prov",
          value: sData.address.state_prov,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.zip_code",
          value: sData.address.zip_code,
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.country",
          value: sData.address.country,
        });
        
      } else {
        
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.first_name",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.last_name",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.contact.organization",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.address_1",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.address_2",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.city",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.state_prov",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.zip_code",
          value: "",
        });
        this.$store.commit(SET_CHECKOUT_FIELD, {
          field: "billing.address.country",
          value: "",
        }); 
      } 
    },

    //validates inputs whenver a value has changed
    validate: function (validProp, valid) {
      // sets checkout property if input value is valid
      this[validProp] = valid;

      //console.log('validate called by ' + validProp)

      /* Check for valid contact */
      if (this.emailValid && this.phoneValid) {
        this.contactValid = true;
      } else {
        this.contactValid = false;
      }

      /* Check for valid shipping */
      if (
        this.firstNameValid &&
        this.lastNameValid &&
        this.street1Valid &&
        this.cityValid &&
        this.stateValid &&
        this.countryValid &&
        this.zipValid 
      ) {
        this.shippingValid = true;
      } else {
        this.shippingValid = false;
      }

      /* Check Credit Card Type */
      /* Get the first two numbers */
      var typeCheck = this.currentCheckoutData.billing.credit_card.number.substring(0,2);
      var ccType=null;

      /* Check if there are enough numbers entered to determine type */
      if(typeCheck.length >= 2) {
        /* Convert to intiger */
        typeCheck=parseInt(typeCheck);

        /* Determine card type */
        if(typeCheck >= 40 && typeCheck <=49) {
          ccType = "V"; //Visa
        } else if ((typeCheck >= 51 && typeCheck <= 55) || (typeCheck >=20 && typeCheck <=29)) {
          ccType = "M"; //Mastercard
        } else if (typeCheck === 34 || typeCheck === 37) {
          ccType = "A"; //American Express
        } else {
          ccType = "Invalid";
        }

        //Set the credit card type in not invalid
        if(ccType != "Invalid"){
          this.$store.commit(SET_CHECKOUT_FIELD, {
            field: "billing.credit_card.type",
            value: ccType,
          });
        }
      }


      /* Check for valid billing */
      if (
        this.billingFirstNameValid &&
        this.billingLastNameValid &&
        this.billingStreet1Valid &&
        this.billingCityValid &&
        this.billingStateValid &&
        this.billingCountryValid &&
        this.billingZipValid
      ) {
        this.billingValid = true;
      } else {
        this.billingValid = false;
      }

      /* Check for valid payment */
      if (
        this.cardNumberValid &&
        this.cardMonthValid &&
        this.cardYearValid &&
        this.cardCcvValid
      ) {
        this.paymentValid = true;
      } else {
        this.paymentValid = false;
      }

      /* Check if shipping should be fetched */
      if (
        this.shippingValid &&
        !this.shippingRatesDisplayed &&
        !this.loadingShippingRates &&
        !this.shippingMethodValid &&
        !this.shippingAddressChecked
      ) {
        /* Get the shipping rates */
        this.loadingShippingRates = true;
        this.getShippingEstimate();
      }

      //check if the shipping notice has been checked - add 'alert' style if not checked
      if(this.shippingNotice){
        this.$refs.shippingNoticeWrapper.classList.remove('alert');
      }else{
        this.$refs.shippingNoticeWrapper.classList.add('alert');
      }



      //check if the international acknowledge should be checked - if state/prov is set to international or country is Canada
      
      if(this.currentCheckoutData.billing.address.state_prov ==='International' || this.currentCheckoutData.billing.address.country ==='CA'){

        // determine if the international checkbox is displayed

          if(this.internationalCheck){
            this.$refs.internationalCheckWrapper.classList.remove('alert');
          }else{
            this.$refs.internationalCheckWrapper.classList.add('alert');
          }
        
      }else{
        //not an international order - pass this check
        this.internationalCheck = true;
      }
      

      /* Check for valid form */
      if(this.contactValid && this.shippingValid && this.shippingMethodValid && this.billingValid && this.paymentValid && this.shippingNotice && this.internationalCheck && !this.poBoxDetected) {
        this.formValid = true;
      } else {
        this.formValid = false;
      }
    },

    // validates all inputs before form is submitted
    validateAllInputs: function () {

      //Shipping Email
      if (this.$refs.shippingEmail) {
        this.$refs.shippingEmail.checkValidation();
      }
      //Shipping Phone
      if (this.$refs.shippingPhone) {
        this.$refs.shippingPhone.checkValidation();
      }

      //Shipping First Name
      if (this.$refs.firstName) {
        this.$refs.firstName.checkValidation();
      }

      //Shipping Last Name
      if (this.$refs.lastName) {
        this.$refs.lastName.checkValidation();
      }

      //Shipping Address
      if (this.$refs.shippingAddress1) {
        this.$refs.shippingAddress1.checkValidation();
      }

      //Shipping City
      if (this.$refs.shippingCity) {
        this.$refs.shippingCity.checkValidation();
      }

      //Shipping State
      if (this.$refs.shippingState) {
        this.$refs.shippingState.checkValidation();
      }

      //Shipping Zip
      if (this.$refs.shippingZip) {
        this.$refs.shippingZip.checkValidation();
      }

      //Shipping Country
      if (this.$refs.country) {
        this.$refs.country.checkValidation();
      }

      //Billing First Name
      if (this.$refs.billingFirstName) {
        this.$refs.billingFirstName.checkValidation();
      }

      //Billing Last Name
      if (this.$refs.billingLastName) {
        this.$refs.billingLastName.checkValidation();
      }

      //Billing Address
      if (this.$refs.billingAddress1) {
        this.$refs.billingAddress1.checkValidation();
      }

      //Billing City
      if (this.$refs.billingCity) {
        this.$refs.billingCity.checkValidation();
      }

      //Billing State
      if (this.$refs.billingState) {
        this.$refs.billingState.checkValidation();
      }

      //Billing Zip
      if (this.$refs.billingZip) {
        this.$refs.billingZip.checkValidation();
      }

      //Billing Country
      if (this.$refs.billingCountry) {
        this.$refs.billingCountry.checkValidation();
      }

      //Check Shipping method

      //Credit card number
      if (this.$refs.billingCCNumber) {
        this.$refs.billingCCNumber.checkValidation();
      }

      //Credit card month
      if (this.$refs.billingCCMonth) {
        this.$refs.billingCCMonth.checkValidation();
      }

      //Credit card year
      if (this.$refs.billingCCYear) {
        this.$refs.billingCCYear.checkValidation();
      }

      //Credit card CCV
      if (this.$refs.billingCCCCV) {
        this.$refs.billingCCCCV.checkValidation();
      }
    },

    //submits the order
    placeOrder: function () {
      this.$store.commit(SET_CHECKOUT_SUMMARY, null);
      this.validateAllInputs();
      if (this.formValid) {
        this.orderSent = true;
        this.$store.dispatch("checkout").then((response) => {
          this.orderResponse = true;
        });
      }
    },

    applyCoupon: function() {

      this.$store.dispatch("applyCoupon").then((response) => {
        this.couponResponse = true;
      });

    }
  },

  //clear out old data
  beforeRouteLeave(to, from, next) {
    this.$store.commit(SET_CHECKOUT_SUMMARY, null);
    this.orderSent = false;
    this.couponValid = false;
    this.couponResponse = false;
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_layouts.scss";
.checkout {
  text-align: center;
  padding-bottom: 50px;
}
.btn {
  @include btn();
}
p {
  a {
    text-decoration: underline;

    &:hover {
      color: #ffffff;
    }
  }
}
fieldset {
  border: none;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;

  &:first-child {
    margin-top: 0px;
  }

  legend {
    display: block;
    width: 100%;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;

    a {
      color: #ffffff;
    }

    .legendDetails {
      float: right;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 6px;

      input {
        display: inline-block;
        width: auto;
        padding: 0;
        margin: 0px 3px 0 0;
      }
    }
  }
}
input {
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  margin-bottom: 15px;
  font-size: 16px;
  color: #ffffff;
}
::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox fix */
}

.details {
  clear: both;
  font-size: 12px;
  margin-top: 0px;
  color: rgba(255, 255, 255, 0.7);
}
.inputHint {
  clear: both;
  font-size: 12px;
  margin-top: -10px;
  padding: 0 10px;
  color: rgba(255, 255, 255, 0.7);
}
.couponRow {
  position:relative;
  margin-top: -40px;
  padding-bottom:20px;

  @include for-small-down {
    margin-top: 20px;
  }

  .couponInput {
    display: flex;
  }
  #couponMessage{
    position:absolute;
    top: 48px;
    left: 25px;
    font-size:12px;
  }
  button {
    margin-left: 20px;
    background-color: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    border: rgba(255, 255, 255, 0.7) 1px solid;
    border-radius: 4px;
    padding: 2px 5px;
    height: 39px;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.6);
      transition: background-color 0.2s ease-out;
    }
  }
}
.couponRow .formInputText {
  margin-left: 20px;
  width: 220px;

  @include for-small-down {
    margin: 0 auto;
  }
}
.loadingSpinner {
  float: left;
  margin-right: 10px;
}
.receipt {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  padding-bottom: 20px;

  h2 {
    font-size: 32px;
    padding: 20px 0;
    margin: 0;
  }

  .discountRow,
  .subtotalRow,
  .shippingRow,
  .taxesRow,
  .totalRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .spacer {
      width: 100%;
    }

    .rowLabel {
      white-space: nowrap;
      text-align: right;
      box-sizing: border-box;
    }
    .rowPrice {
      white-space: nowrap;
      padding-left: 20px;
      margin-right: 20px;
      min-width: 110px;
      text-align: right;
      box-sizing: border-box;
    }
  }

  .totalRow {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    .rowLabel,
    .rowPrice {
      padding-top: 10px;
      border-top: 1px solid #ffffff;
    }
  }
  .priceRows {
    margin-top: 13px;
  }
}
.shippingNotice{
  /*
  background-color:rgba(0,0,0,0.1);
  border-radius:5px;
  padding:20px;
  */
  margin-bottom: 40px;
}
.legendDetails.alert,
.poBoxMessage p.details{
  color:red;
}
.legendDetails.alert .customCheck{
  border-color:red;
}
.orderBtn {
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.1);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    background-color: #000000;
    cursor: pointer;
  }
}
.checkoutDetails {
  text-align: left;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  h1 {
    font-size: 18px;
    margin: 20px 0 0;
  }
  p {
    margin: 0;
  }
}

.customCheckContainer {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;

  :hover {
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width:100%;
    height:100%;

    &:checked ~ .customCheck {
      &:after {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .customCheck {
    pointer-events: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    &:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin-left: 2px;
      margin-top: 2px;
      border-radius: 2px;
    }
  }
}

.error {
  border: 1px solid red;
}
.errorText{
  color: red;
}

.shippingMethod {
  position: relative;

  select {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;

    option {
      color: #000000;
      font-size: 12px;
    }
  }

  select:focus + label,
  select.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
  }
}

.orderSummaryWrapper,
.orderProcessingWrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 0 40px;
}

.orderProcessingWrapper {
  text-align: center;
  padding: 40px 0;
  img {
    float: none;
    margin: 0 auto;
  }
}

.gameArrow {
  position: relative;
  display: inline-block;
  width: 18px;
  line-height: 20px;
  border-bottom: 0px;
  font-size: 14px;
}
.upArrow span,
.downArrow span {
  display: inline-block;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.upArrow span {
  animation-name: asc;
  transform: rotateX(180deg);
}
.downArrow span {
  animation-name: desc;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.failed-order-summary{
  a{
    font-weight:bold;
    text-decoration:underline;
  }
}
.failed-order-details{
  margin:20px;
  text-align:left;
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:20px;

  @include for-small-down {
    grid-template-columns:1fr;
  }
}

@keyframes desc {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes asc {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}
</style>